import axios from 'axios'

export default {
    namespaced: true,

    state: {
        userEmail: null,
        authenticated: sessionStorage.getItem('authenticated') || false,
        user: sessionStorage.getItem('user') || null,        
        overlay: false,        
        loginErrors: '',
        admin: sessionStorage.getItem('admin') || false, 
        editTermReport: false,                            
    },

    getters: {
        getUserEmail (state) {
            return state.userEmail
        },

        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },

        getOverlay(state){
            return state.overlay
        },

        getLoginErrors(state){
            return state.loginErrors
        },
        
        getAdmin (state) {
            return state.admin
        },

        getEditTermReport(state){
            return state.editTermReport
        }
    },

    mutations: {
        setUserEmail (state, value) {
            state.userEmail = value
        },
        
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setOverlay (state, value){
            state.overlay = value
        }, 

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setAdmin (state, value) {
            state.admin = value
        },

        setEditTermMark(state, value){
            state.editTermReport = value
        }
        
    },

    actions: {
        async getUser({ getters }){           
            console.log("getting user information...");
            const response = await axios.get('/sanctum/csrf-cookie');  
            console.log(response);          
            let url = '/api/user-employee/' + getters.getUserEmail;
            return axios.get(url);
        }, 

        serverError({ commit }, err ){            
            if(err.response){
                console.log(err.response)
                var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'data');                                     
                if( hasErrors && err.response.data.errors.message.length != 0){
                    commit('setLoginErrors', err.response.data.errors.message[0]);
                }
                else{
                    commit('setLoginErrors', err.response.statusText);
                }                    
                commit('setAuthenticated', false);
                commit('setOverlay', false);
            }
            
       }
    }
}