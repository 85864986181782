<template>
     <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid
            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col>
                        <v-card 
                            max-width="400"
                            class="elevation-12 pa-4 mx-auto">
                            <v-row
                                justify="center"
                            >
                                <v-img
                                    src="../../assets/logo.png"
                                    max-width="120"
                                    contain
                                    class="d-flex"
                                ></v-img>
                            </v-row>
                            <v-row no-gutters>                               

                                <v-col cols="12">
                                   
                                    <div class="pa-4">
                                        <v-alert                                                    
                                            type="error"
                                            prominent                                                  
                                            v-if="getloginErrors"
                                            dense
                                        >
                                            <div>{{ getloginErrors }}</div>
                                        </v-alert>    
                                    </div>
                                    <div class="text-center font-weight-light primary--text">Welcome to</div>
                                    <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>  
                                    <v-card-text>                                                
                                        <div class="primary--text text-h5 text-left">
                                            <slot name="header"></slot>    
                                        </div>                                            
                                    </v-card-text>
                                    <div class="text-right mt-4 mb-4">                                                
                                        <v-btn 
                                            color="primary"
                                            @click="signIn" 
                                            dark
                                            block
                                        >Sign In</v-btn>
                                    </div>   
                                    <slot name="username"></slot>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="overlay"
                    opacity="0.8"
                    z-index="15"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-container>               
        </v-main>
        
    </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import msalInstance from '../../services/authService';

export default {
    name: 'LoginTermReports',

    data: () => ({        
       
    }),

    computed: {
        ...mapGetters({
            authenticated: 'auth/getAuthenticated',
            resetPassword: 'auth/getResetPassword',
            getOverlay: 'auth/getOverlay',
            schoolName: 'app/getSchoolName',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',                     
            overlay: 'auth/getOverlay',                        
        }),
        
    },

    methods: {
        ...mapActions({            
            getUser: 'auth/getUser',            
        }),

        ...mapMutations({
            setUserEmail: 'auth/setUserEmail',
            setAuthenticated: 'auth/setAuthenticated',
            setUser: 'auth/setUser',
            setLoginError: 'auth/setLoginErrors',
            setOverlay: 'auth/setOverlay',
                                                        
        }),        

        async signIn () {
            this.setOverlay(true);            
            try {
                const loginResponse = await msalInstance.loginPopup({})
                const { account } = loginResponse;
                this.setUserEmail(account.username);                
                const { data: { permissions, employee } } = await this.getUser();
                
                const user = {
                    initials: employee.first_name[0]  +
                    employee.last_name[0],
                    first_name: employee.first_name,
                    last_name: employee.last_name,
                    employee_id: employee.id,
                    permissions: permissions,
                    email: account.username 
                }

                this.setAuthenticated(true);
                this.setUser(JSON.stringify(user));

                sessionStorage.setItem("user", JSON.stringify(user));                
                sessionStorage.setItem("authenticated", "true");               
                
                this.$router.replace('/app');
                                
            } catch (error) {
                if(error.response){
                    console.log(error.response)
                    const { data: { message } } = error.response;
                    this.setLoginError(message);
                }
                else{
                    console.log(error);
                    this.setLoginError("An error has occured. Please contact your administrator");
                } 
            }
            this.setOverlay(false);
        },       
        
        keyPress (e) {
            if(e.keyCode === 13){
                this.signIn();
            }
        },       
        
    },
}
</script>

<style scoped>
    .container-background{
        background-image: url('../../assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }
       
</style>