<template>
    <v-card
        flat
    >
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search for teacher"
                single-line
                hide-details
                clearable
            ></v-text-field>
        </v-card-title>    
        <v-data-table
            class="mt-10"
            :headers="headers"
            :items="employees"
            :search="search"
            fixed-header
            height="25vh"
            :loading="loading"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    text
                    small
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="editEmployee(item)"
                >
                    <v-icon
                        small
                        class="mr-2"                                       
                    >
                        mdi-pencil
                    </v-icon>
                    Edit
                </v-btn>  
                <v-btn
                    text
                    small
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="deleteEmployee(item)"
                >
                    <v-icon
                        small
                        class="mr-2"                                       
                    >
                        mdi-delete
                    </v-icon>
                    Delete
                </v-btn>                               
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogEdit"
            max-width="400px"
            persistent
        >
            <v-card
                class="pa-4"
            >
                <v-card-title
                    class="pl-0"
                >Edit Teacher</v-card-title>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.first_name"
                            label="First Name"
                            @blur="updateTeacher('first_name')"                                
                        >
                            <template v-slot:append>
                                <v-fade-transition leave-absolute>
                                    <v-progress-circular
                                        v-if="firstNameEditing"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                    <v-icon
                                        v-else-if="firstNameSaved"
                                        color="info"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                    <v-icon
                                        v-else-if="firstNameError"
                                        color="red"
                                    >
                                        mdi-alert-circle 
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.last_name"
                            label="Last Name"
                            @blur="updateTeacher('last_name')"
                        >
                            <template v-slot:append>
                                <v-fade-transition leave-absolute>
                                    <v-progress-circular
                                        v-if="lastNameEditing"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                    <v-icon
                                        v-else-if="lastNameSaved"
                                        color="info"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                    <v-icon
                                        v-else-if="lastNameError"
                                        color="red"
                                    >
                                        mdi-alert-circle 
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.email"
                            label="Email"
                            @blur="updateTeacher('email')"
                            :error-messages="errorMessages"
                        >
                            <template v-slot:append>
                                <v-fade-transition leave-absolute>
                                    <v-progress-circular
                                        v-if="emailEditing"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                    <v-icon
                                        v-else-if="emailSaved"
                                        color="info"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                    <v-icon
                                        v-else-if="emailError"
                                        color="red"
                                    >
                                        mdi-alert-circle 
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        outlined
                        color="primary"                        
                    >
                        Save
                    </v-btn>
                    <v-btn
                        text
                        outlined
                        color="primary"
                        @click="closeEditTeacher"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogDelete"
            max-width="600px"
            persistent
        >
            <v-card
                v-if="confirmDelete"
            >
                <v-card-title>{{ teacherDeleted }}</v-card-title>
                <v-card-text>                                        
                    <v-select
                        :items="statuses"
                        v-model="status"
                        label="Status"                                            
                        item-text="detail"
                        item-value="id"
                        autofocus
                        hide-details
                        ref="student-status"
                        clearable
                        open-on-clear
                    ></v-select>
                </v-card-text>
                <v-card-actions
                    class="px-6"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="closeDelete"
                        outlined
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteConfirm"
                        outlined
                    >
                        Delete
                    </v-btn>                                        
                </v-card-actions>
            </v-card>
            <v-expand-transition>
                <v-card
                    v-show="deleting"
                    color="primary"
                    dark
                    class="pa-4"
                >
                    <v-card-text>
                        <span class="mr-2">Deleting</span> {{ teacherDeleted }}
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0 mt-4"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-expand-transition>
        </v-dialog> 
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        search: '',
        loading: false,
        editedEmployee: {},
        deletedEmployee: {},
        headers: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '120'},
            {text: 'First Name', value: 'first_name', width: '120'},
            {text: 'User Name', value: 'email', width: '120'},
            {text: '', value: 'actions', sortable: 'false', }
        ],
        dialogEdit: false,
        dialogDelete: false,
        firstNameEditing: false,
        firstNameSaved: false,
        firstNameError: false,
        lastNameEditing: false,
        lastNameSaved: false,
        lastNameError: false,
        emailEditing: false,
        emailSaved: false,
        emailError: false,
        errorMessages: [],
        confirmDelete: false,
        deleting: false,        
        status: 2,
    }),
    watch: {
        employees: {
            handler(val){
                if(val.length != 0) this.loading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            employees: 'employees/getEmployees',
            statuses: 'employees/getEmployeeStatuses',
        }),
        teacherDeleted () {
            return this.deletedEmployee.first_name + ' ' + this.deletedEmployee.last_name
        }
        
    },
    methods: {
        ...mapActions({
            postEmployee: 'employees/postEmployee',            
            delete: 'employees/deleteEmployee',
            getEmployees: 'employees/getEmployees',            
        }),
        ...mapMutations({
            setEditedEmployee: 'employees/setEditedEmployee',
            setDeletedEmployee: 'employees/setDeletedEmployee',
            setEmployees: 'employees/setEmployees',
            setSnack: 'employees/setSnack',
            setSnackText: 'employees/setSnackText',
        }),

        editEmployee(item){
            console.log(item);
            this.editedEmployee = Object.assign({}, item);
            this.dialogEdit = true;
        },
        deleteEmployee(item){
             console.log(item);
             console.log(this.statuses);
             this.deletedEmployee = item;             
             this.dialogDelete = true;
             this.confirmDelete = true;
        },
        async deleteConfirm(){
            console.log('delete confirmed');
            this.confirmDelete = false;
            this.deleting = true;            
            this.setDeletedEmployee({
                id: this.deletedEmployee.id,
                employee_status_id: this.status
            })
            try {
                let response = await this.delete();
                this.deleting = false;
                this.dialogDelete = false;
                response = await this.getEmployees();
                this.setEmployees(response.data);                                
                //this.employees = response.data;  
                //console.log(response);
               this.setSnack(true);
               this.setSnackText(this.deletedEmployee.first_name + ' ' + this.deletedEmployee.last_name + ' deleted '); 
            } catch (error) {
                console.log(error);
                this.setSnack(true);
                this.setSnackText('An Error Occured');                
            }
        },
        closeDelete(){
            this.dialogDelete = false;
            this.status = 2;
        },
        async updateTeacher(field){
            this.editingStatus(field);                     
            this.setEditedEmployee(this.editedEmployee);
            console.log(this.editedEmployee);            
            try {
                let response = await this.postEmployee();
                this.errorMessages = [];
                this.editComplete(field);
                
                response = await this.getEmployees();
                this.setEmployees(response.data);                                
                //this.employees = response.data;                
            } catch (error) {
                this.editError(field);
                if(error.response){
                    console.log(error.response);
                    this.errorMessages.push(error.response.data.message);   
                }              
                else console.log(error);
            }
        },
        closeEditTeacher(){
            this.dialogEdit = false;
            this.firstNameEditing = false;
            this.firstNameSaved = false;
            this.firstNameError = false;
            this.lastNameEditing = false;
            this.lastNameSaved = false;
            this.lastNameError = false;
            this.emailEditing = false;
            this.emailSaved = false;
            this.emailError = false;
            this.errorMessages = [];
        },
        editingStatus(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = false;
                    this.firstNameError = false;
                    this.firstNameEditing = true;
                    break;
                case 'last_name':
                    this.lastNameSaved = false;
                    this.lastNameError = false;
                    this.lastNameEditing = true;
                    break;
                case 'email':
                    this.emailSaved = false;
                    this.emailError = false;
                    this.emailEditing = true;
                    break;
            }
        },
        editComplete(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = true;
                    this.firstNameError = false;
                    this.firstNameEditing = false;
                    break;
                case 'last_name':
                    this.lastNameSaved = true;
                    this.lastNameError = false;
                    this.lastNameEditing = false;
                    break;
                case 'email':
                    this.emailSaved = true;
                    this.emailError = false;
                    this.emailEditing = false;
                    break;
            }
        },
        editError(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = false;
                    this.firstNameError = true;
                    this.firstNameEditing = false;
                    break;
                case 'last_name':
                    this.lastNameSaved = false;
                    this.lastNameError = true;
                    this.lastNameEditing = false;
                    break;
                case 'email':
                    this.emailSaved = false;
                    this.emailError = true;
                    this.emailEditing = false;
                    break;
            }
        }
    },
    
}
</script>