<template>
    <v-dialog
        :value="dialog"
        max-width="780px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                @click="dialog=true"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
            >
                View Attendance
            </v-btn>                            
        </template>

        <v-card>
            <v-card-title>Student Attendance</v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col cols="2">
                        <v-card flat>
                            Current Population
                        </v-card>
                    </v-col>
                    <v-col cols="8"></v-col>
                    <v-col cols="2">
                        <v-card flat>
                            Late Population
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
                
    </v-dialog>    
</template>

<script>
export default {
    data: () => ({
        dialog: false,
    })
}
</script>