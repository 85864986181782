<template>
    <v-btn        
        text
        v-on:click="signOut"
        color="primary"
      >
        <span class="mr-2">Sign Out</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import msalInstance from '../../services/authService';

export default {
    props: {
        signOutOption: {
            type: String,
            default: null,
        }
    },

    computed: {
       ...mapGetters({
            authenticated: 'auth/getAuthenticated',
            userEmail: 'auth/getUserEmail',
       })
    },

    watch: {
        signOutOption: {
            handler (val) {                
                this.setSignOutOption(val);
            }
        }
    },

    methods: { 
        ...mapMutations({
            setOverlay: 'app/setAppOverlay',            
            setAuthenticated: 'auth/setAuthenticated',
            setDialogSignOut: 'app/setDialogSignOut',
        }),

        signOut () {
            this.setDialogSignOut(true);
            
        },
        
        async setSignOutOption (value) {
            this.setAuthenticated(false);
            this.setOverlay(true);
            sessionStorage.clear();
            if(value == 'app'){                
                setTimeout(() => {
                    this.$router.replace('/');
                    this.setOverlay(false);
                }, 1500)
            }
            else if(value == 'msaccount'){
                try {
                    await msalInstance.logoutPopup()
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                } 
                this.setOverlay(false);            
                this.$router.replace('/');
            }
           
                      
            
            
        }
    }
}
</script>